import { apiGet_last_identifiy_proof } from '@/resource'
export default {
  data() {
    return {
      idPendingReason: null,
      idCustomPendingReason: '',
      poaPendingReason: null,
      poaCustomPendingReason: '',
      idRecode: null,
      poaRecode: null,
    }
  },
  mounted() {
    this.getId()
    this.getPoa()
  },
  methods: {
    getId() {
      // Submitted: 0
      // Pending: 1
      // Completed: 2
      // Rejected: 3
      // Re-Audit: 4
      if (this.pendingIDProofApproval) {
        this.idPendingReason = null
        this.idCustomPendingReason = null
        apiGet_last_identifiy_proof(12).then(resp => {
          const data = resp.data
          if (data.code == 0) {
            if (data.data.status == 1) {
              this.idRecode = data.data.id
              this.$store.commit('common/setKeepUploadID', true)

              if (data.data.pending_reason == 7) this.idCustomPendingReason = data.data.custom_pending_reason
              else this.idPendingReason = data.data.pending_reason_text
            }
            if (data.data.status == 2) {
              this.$store.commit('common/setRequireIDProof', false)
              this.$store.commit('common/setPendingIDProofApproval', false)
            }
            if (data.data.status == 3) {
              this.$store.commit('common/setRequireIDProof', true)
              this.$store.commit('common/setPendingIDProofApproval', false)
            }
            if (data.data.status == 4) {
              this.$store.commit('common/setRequireIDProof', false)
              this.$store.commit('common/setPendingIDProofApproval', true)
            }
          }
        })
      }
    },
    getPoa() {
      if (this.pendingAddressProofApproval) {
        this.poaPendingReason = null
        this.poaCustomPendingReason = null
        apiGet_last_identifiy_proof(11).then(resp => {
          const data = resp.data
          if (data.code == 0) {
            if (data.data.status == 1) {
              this.poaRecode = data.data.id
              this.$store.commit('common/setKeepUploadPOA', true)

              if (data.data.pending_reason == 7) this.poaCustomPendingReason = data.data.custom_pending_reason
              else this.poaPendingReason = data.data.pending_reason_text
            }
            if (data.data.status == 2) {
              this.$store.commit('common/setRequireAddressProof', false)
              this.$store.commit('common/setPendingAddressProofApproval', false)
            }
            if (data.data.status == 3) {
              this.$store.commit('common/setRequireAddressProof', true)
              this.$store.commit('common/setPendingAddressProofApproval', false)
            }
            if (data.data.status == 4) {
              this.$store.commit('common/setRequireAddressProof', false)
              this.$store.commit('common/setPendingAddressProofApproval', true)
            }
          }
        })
      }
    },
  },
  computed: {
    pendingIDProofApproval() {
      return this.$store.state.common.pendingIDProofApproval
    },
    pendingAddressProofApproval() {
      return this.$store.state.common.pendingAddressProofApproval
    },
    requireAddressProof() {
      return this.$store.state.common.requireAddressProof
    },
    requireIDProof() {
      return this.$store.state.common.requireIDProof
    },
    keepUploadID() {
      return this.$store.state.common.keepUploadID
    },
    keepUploadPOA() {
      return this.$store.state.common.keepUploadPOA
    },
    showIdentityProofAlert() {
      return this.requireAddressProof || this.requireIDProof || this.keepUploadID || this.keepUploadPOA
    },
  },
}
