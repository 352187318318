<template>
  <div id="block">
    <div class="top">
      <img v-if="method == 'id'" src="@/assets/images-1/id.png" />
      <img v-if="method == 'address'" src="@/assets/images-1/poa.png" />
    </div>
    <div class="span" v-html="span"></div>
    <div class="middle">
      <span v-if="customPendingReason || PendingReason"
        v-html="$t(`identityProof.reason`, { reason: customPendingReason ? customPendingReason : PendingReason })">
      </span>
    </div>
    <div class="upload">
      <div v-if="keepUpload" class="upload_tip">
        {{ $t('register.confirmYourId.uploadTip') }}
      </div>
      <el-form label-position="top" status-icon>
        <vUpload :limit="limit" v-on:updateFileInfo="updateFileInfo" :selfText="true">
          <!-- <div class="message" v-if="showTick">
            <span class="message_icon el-icon-circle-check"></span>
          </div> -->
          <!-- <div class="message">
            <span class="el-icon-plus"></span>
            <span class="text">{{ $t('register.btn.upload') }}</span>
          </div> -->
          <svg-icon iconClass="upload"></svg-icon>
          <p class="upload_btn">{{ $t('register.btn.upload') }}</p>
        </vUpload>
      </el-form>
    </div>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload'

export default {
  name: 'block',
  data() {
    return {
      fileLength: 0,
    }
  },
  props: {
    method: String,
    limit: Number,
    PendingReason: String,
    customPendingReason: String,
    keepUpload: Boolean,
  },
  components: { vUpload },
  computed: {
    span() {
      switch (this.method) {
        case 'address':
          return this.$t('identityProof.popup.addressProofSpan')
        case 'id':
          return this.$t('identityProof.popup.idProofSpan')
      }
    },
    // showTick() {
    //   return this.fileLength > 0
    // },
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileLength = fileInfo.fileList.length
      this.$emit('syncFile', fileInfo.fileList)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/block.scss';

.svg-icon {
  font-size: 32px;
}

.upload_btn {
  font-size: 12px;
  color: #7A8699;
  line-height: 18px;
  margin-top: 4px;
}
</style>
