<template>
  <div>
    <StatusAlert :title="$t('notification.pendingIdentityProof.header')" type="warning" show-icon :closable="false">
      <div class="alert_description">
        <i18n path="notification.pendingIdentityProof.content" tag="p">
          <template v-slot:doc>{{ doc }}</template>
          <template v-slot:doc2>{{ doc2 }}</template>
        </i18n>
        <router-link class="description_btn" :to="{ name: 'depositFund' }">
          {{ $t('register.btn.fundnow') }}
        </router-link>
      </div>
    </StatusAlert>
  </div>
</template>

<script>
import Card from '@/components/Card'
import StatusAlert from '@/components/customUI/StatusAlert.vue'

export default {
  name: 'notification',
  components: { Card, StatusAlert },
  props: { id: Boolean, poa: Boolean },
  data() {
    return {
      doc2: '',
    }
  },
  computed: {
    doc() {
      let doc
      if (this.id && this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.both2')
        doc = this.$t('notification.pendingIdentityProof.both')
      } else if (this.id) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single')
        doc = this.$t('notification.pendingIdentityProof.id')
      } else if (this.poa) {
        this.doc2 = this.$t('notification.pendingIdentityProof.single')
        doc = this.$t('notification.pendingIdentityProof.poa')
      }

      return doc
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/home/notification.scss';
.alert_description {
  display: flex;
  align-items: center;

  .description_btn {
    color: #007aff;
    text-decoration: underline;
  }
}
</style>
