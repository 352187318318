<template>
  <div class="idPoa">
    <SDialog id="UploadIdOrAddress" title="补充材料" ref="vDialog" :visible.sync="show">
      <div class="header">
        {{ $t('identityProof.home.header') }}
      </div>
      <div class="body">
        <div v-html="$t('identityProof.popup.header', { name: individualUserName })" class="bottom"></div>
        <Block
          :limit="uploadLimit"
          :PendingReason="idPendingReason"
          :customPendingReason="idCustomPendingReason"
          method="id"
          :keepUpload="secondNationalId"
          v-if="requireIDProof || keepUploadID"
          @syncFile="syncIdFile"
        ></Block>
        <idPoaFormComponent ref="idPoaForm" v-if="showIdForm" :userData="foamData"></idPoaFormComponent>
        <Block
          :limit="6"
          :PendingReason="poaPendingReason"
          :customPendingReason="poaCustomPendingReason"
          method="address"
          v-if="requireAddressProof || keepUploadPOA"
          @syncFile="syncPoaFile"
        ></Block>
      </div>
      <div class="errMsg" v-if="errorMsgTrigger">{{ $t('common.formValidation.file') }}</div>
      <template #footer>
        <el-button type="primary" data-testid="submit" @click="onSubmit">{{ $t('common.button.upload') }}</el-button>
      </template>
    </SDialog>

    <SDialog id="success" :visible.sync="success" :showFooter="false">
      <StatusMessage type="success">
        <template #content>
          <p class="home_status_content">{{ $t('dialog.identityProof.success') }}</p>
        </template>
      </StatusMessage>
    </SDialog>
  </div>
</template>

<script>
import Block from '@/components/home/UploadIdAddress/Block'
import idPoaFormComponent from '@/components/home/UploadIdAddress/Form'
import { apiVerificationIdentityProofs, apiAttach_new_document } from '@/resource'
import { apiGetData, apiProcess } from '@/resource/register'

const NATIONID_REQUIRELENGTH = 2

export default {
  name: 'uploadIdOrAddress',
  props: {
    visible: Boolean,
    idPendingReason: String,
    idCustomPendingReason: String,
    poaPendingReason: String,
    poaCustomPendingReason: String,
    idRecode: Number,
    poaRecode: Number,
  },
  components: { Block, idPoaFormComponent },
  data() {
    return {
      individualUserName: this.$store.state.common.individualUserName,
      show: false,
      success: false,
      errorMsgTrigger: false,
      idFiles: [],
      poaFiles: [],
      idProofType: 0,
      foamData: null,
      step: 5,
    }
  },
  computed: {
    requireIDProof() {
      return this.$store.state.common.requireIDProof
    },
    requireAddressProof() {
      return this.$store.state.common.requireAddressProof
    },
    keepUploadID() {
      return this.$store.state.common.keepUploadID
    },
    keepUploadPOA() {
      return this.$store.state.common.keepUploadPOA
    },
    registerStep() {
      return this.$store.state.common.registerStep
    },
    showIdForm() {
      if (!this.requireIDProof) return false
      return this.idFiles.length > 0
    },
    isNationalId() {
      return this.idProofType === 1
    },
    secondNationalId() {
      return this.isNationalId && this.idFiles.length > 0
    },
    uploadLimit() {
      return this.isNationalId ? NATIONID_REQUIRELENGTH : 6
    },
  },
  watch: {
    show(bool) {
      this.disableVisible(bool)
    },
    visible: {
      immediate: true,
      handler(bool) {
        this.show = bool
      },
    },
  },
  methods: {
    getUserData() {
      apiGetData(this.step)
        .then(resp => {
          this.idProofType = resp.data.data.idType
          this.foamData = resp.data.data
        })
        .catch(err => {
          this.errorMessage($t('responseMsg.' + result.data.code))
        })
    },
    syncIdFile(files) {
      this.idFiles = files
      this.$nextTick(() => this.$refs.vDialog.setFullscreen())
    },
    syncPoaFile(files) {
      this.poaFiles = files
    },
    disableVisible(bool) {
      this.$emit('update:visible', bool)
    },
    onSubmit() {
      if (this.idFiles.length == 0 && this.poaFiles.length == 0) {
        this.errorMsgTrigger = true
        return
      }

      if (this.registerStep < 6) this.postApiProcess()

      if (this.requireAddressProof && this.poaFiles.length > 0) this.checkIdPoaForm(11)
      if (this.requireIDProof && this.idFiles.length > 0) this.checkIdPoaForm(12)

      if (this.poaRecode && this.poaFiles.length > 0) this.attachInsertAPi(11, this.poaRecode, this.poaFiles)
      if (this.idRecode && this.idFiles.length > 0) this.attachInsertAPi(12, this.idRecode, this.idFiles)
    },
    postApiProcess() {
      apiProcess({
        idDocFilePathList: [],
        poaDocFilePathList: [],
        step: 5,
      }).then(res => {
        this.$store.commit('common/setRegisterStep', 6)
      })
    },
    checkIdPoaForm(type) {
      const getIdPoaFormComponent = this.$refs.idPoaForm

      if (getIdPoaFormComponent) {
        getIdPoaFormComponent.$refs['ruleForm'].validate(valid => {
          if (!valid) return
          else this.standardInsertApi(type, getIdPoaFormComponent.form)
        })
      } else this.standardInsertApi(type)
    },
    getStandardData(type, idPoaForm = null) {
      if (type === 11) {
        return {
          filePathList: [...this.poaFiles],
          fileType: type,
        }
      } else if (type === 12) {
        return {
          filePathList: [...this.idFiles],
          fileType: type,
          countryId: idPoaForm.countryId,
          idType: idPoaForm.idType,
          idNumber: idPoaForm.idNumber,
          firstName: idPoaForm.firstName,
          middleName: idPoaForm.middleName,
          lastName: idPoaForm.lastName,
        }
      }
    },
    standardInsertApi(type, idPoaForm = null) {
      console.log(this.getStandardData(type, idPoaForm))
      apiVerificationIdentityProofs(this.getStandardData(type, idPoaForm)).then(resp => {
        if (resp.data.code == 0) {
          this.success = true
          if (this.idFiles.length > 0 && this.idRecode == null) {
            this.$store.commit('common/setPendingIDProofApproval', true)
            this.$store.commit('common/setRequireIDProof', false)
            this.idFiles = []
          }
          if (this.poaFiles.length > 0 && this.poaRecode == null) {
            this.$store.commit('common/setPendingAddressProofApproval', true)
            this.$store.commit('common/setRequireAddressProof', false)
            this.poaFiles = []
          }
        }
        this.disableVisible(false)
      })
    },
    attachInsertAPi(type, id, files) {
      apiAttach_new_document({ documentId: id, documentType: type, docPaths: files }).then(resp => {
        if (resp.data.code == 0) {
          if (type == 11) {
            this.$store.commit('common/setKeepUploadPOA', false)
            this.$store.commit('common/setPendingAddressProofApproval', true)
            this.poaFiles = []
          }

          if (type == 12) {
            this.$store.commit('common/setKeepUploadID', false)
            this.$store.commit('common/setPendingIDProofApproval', true)
            this.idFiles = []
          }
        }
        this.disableVisible(false)
      })
    },
  },
  mounted() {
    this.getUserData()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/index.scss';
</style>
